<template>
    <div class="base container-fluid">
        <!--div class="child-selector">
            <div :class="getBtnClass(0)" @click="setSelection(0)">Importados</div>
        </div-->

        <Importados v-if="selection === 0" />
    </div>
</template>

<style scoped>
.base {
    padding: 0;
}
.child-selector {
    display: flex;
    flex-direction: row;
}
.btn {
    padding: 1em;
    border: 2px solid rgba(0,128,0,0.5);
    color: rgba(0,128,0,0.5);
    border-radius: 27px;
    font-weight: bold;
    font-size: 1em;
    margin: 0 0.5em;
    cursor: pointer;
}
.btn:nth-child(1) {
    margin-left: 0;
}
.btn:last-child {
    margin-right: 0;
}
.btn.selected {
    background-color: rgba(0,128,0,0.5);
    color: white;
}
</style>

<script lang="js">
import Importados from './Importados.vue';
import EventBus from '../../../Bus/bus';

export default {
    components: { Importados },
    data () {
        return {
            selection: 0
        }
    },
    methods: {
        getBtnClass (index) {
            var selected = this.selection === index;
            var tail     = selected ? ' selected' : '';
            var head     = 'btn btn-';
            switch (index) {
                case 0: return `${head}edit${tail}`;
                default: return `${head}generic${tail}`;
            }
        },
        setSelection (index) {
            this.selection = index;
        },
        ebReload (data) {
            if (!data) { return; }
            this.selection = undefined;
        }
    },
    created () {
        EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
    },
    beforeDestroy () {
        EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
    }
}
</script>